
import {Navbar} from './components';
import {Home, Contact, About} from './pages';
import { Route, Routes } from 'react-router-dom';

function App() {
 
  return (
<>
<Navbar/> 
  <div className="container">
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='/contact' element={<Contact />} />
      <Route path='/about' element={<About />} />
    </Routes>
  </div>
</>
  );
}

export default App;
